import { CloseOutlined } from "@ant-design/icons";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";

import { Button } from "@/components/Button";

export const DelButton = forwardRef(function DelButton(props, ref) {
  return (
    <Button
      css={css`
        border: none;
        color: ${theme.colors.neutral006};
        font-size: 12px;
      `}
      ghost
      shape="circle"
      icon={<CloseOutlined />}
      {...props}
      ref={ref}
    />
  );
} satisfies Button.Type);
