import { EyeOutlined } from "@ant-design/icons";
import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";
import { memo } from "@zeffiroso/utils/react/memo";
import type { TableColumnProps as ColumnProps } from "antd";
import { Form } from "antd";
import { arrayMoveImmutable } from "array-move";
import { type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Alert } from "@/components/Alert";
import { Flex } from "@/components/Box";
import { Button } from "@/components/Button";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { DndTable } from "@/components/Table/DndTable";
import { SubText } from "@/components/Typography";
import { MainLayout } from "@/layout/MainLayout";
import { AutoAssignmentRuleAssigneeTypeLabel } from "@/resources/autoAssignmentRule/assignee/AutoAssignmentRuleAssigneeTypeLabel";
import { TeamNameById } from "@/resources/team/TeamNameById";
import { UserNameById } from "@/resources/user/UserNameById";
import { routerUtils } from "@/router/routerUtils";
import { compileToString } from "@/router/utils/compileTo";
import { useSwitchRulePriority } from "@/routes/Settings/Assignment/pages/RoutingRules/application/routingRules";
import { RuleDeleteButton } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RoutingRules/RuleDeleteButton";
import { RuleStatusSwitch } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RoutingRules/RuleStatusSwitch";
import { UserNonActiveStaus } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RoutingRules/UserNonActiveStaus";
import { useUserPermission } from "@/shared/application/user";
import { EditOutlined as EditSvg } from "@/shared/icons/common/EditOutlined";

const Container = styled.div`
  width: 100%;
  max-width: 581px;
`;

const Description = styled(SubText)`
  display: block;
  margin-bottom: 16px;
`;

const MAX_AUTO_ASSIGNMENT_RULE_COUNT = 150;

const EditLinkButton = memo(function ({
  ruleId,
}: {
  ruleId: CantataTypes["AutoAssignmentRule"]["id"];
}) {
  const to = useMemo(
    () =>
      compileToString({
        pathname: "/settings/assignment/routing-rules/edit/:ruleId",
        params: {
          ruleId,
        },
      }),
    [ruleId],
  );
  return <NarrowIconButton icon={<EditSvg />} size={20} to={to} />;
});

const ViewLinkButton = memo(function ({
  ruleId,
}: {
  ruleId: CantataTypes["AutoAssignmentRule"]["id"];
}) {
  const to = useMemo(
    () =>
      compileToString({
        pathname: "/settings/assignment/routing-rules/view/:ruleId",
        params: {
          ruleId,
        },
      }),
    [ruleId],
  );
  return <NarrowIconButton icon={<EyeOutlined />} size={20} to={to} />;
});

function useColumns() {
  const { t } = useTranslation();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const { hasPermission } = useUserPermission();
  const draggerColumnRender = useMemo(
    () =>
      DndTable.generateDraggerColumnRender({
        draggerProps: {
          tooltipProps: {
            title: t("resource.autoAssignmentRule.dnd.dragger.tooltip"),
          },
        },
      }),
    [t],
  );
  const userCanEdit = hasPermission("editAutoAssignmentRule");
  return useMemo<ColumnProps<CantataTypes["AutoAssignmentRule"]>[]>(
    () => [
      {
        title: t("assignment.sorter"),
        render: userCanEdit
          ? draggerColumnRender
          : (_value, _record, index) => index + 1,
      },
      {
        title: t("assignment.status"),
        dataIndex: "status",
        render: (status, rule) => {
          return (
            <RuleStatusSwitch
              orgId={orgId}
              ruleId={rule.id}
              status={status}
              {...(!userCanEdit ? { disabled: true } : null)}
            />
          );
        },
      },
      {
        title: t("assignment.ruleName"),
        dataIndex: "name",
      },
      {
        title: t("assignment.assignee"),
        dataIndex: ["assignee", "type"],
        render: (_, rule) => {
          switch (rule.assignee.type) {
            case "by-queue":
              return (
                <AutoAssignmentRuleAssigneeTypeLabel
                  type={rule.assignee.type}
                />
              );
            case "an-agent":
              return (
                <>
                  <UserNameById userId={rule.assignee.userId} />
                  <UserNonActiveStaus userId={rule.assignee.userId} />
                </>
              );
            case "a-team":
              return (
                <TeamNameById orgId={orgId} teamId={rule.assignee.teamId} />
              );
            case null:
              return <AutoAssignmentRuleAssigneeTypeLabel type={null} />;
            default:
              rule.assignee satisfies never;
              throw new Error(
                inspectMessage`Unhandled assignee type: ${rule.assignee}`,
              );
          }
        },
      },
      {
        render: (rule: CantataTypes["AutoAssignmentRule"]) => {
          return (
            <Flex
              css={css`
                width: 56px;
                flex-direction: row;
                justify-content: flex-end;
                gap: 16px;

                svg {
                  color: ${theme.colors.neutral005};

                  &:hover,
                  &:active {
                    color: ${theme.colors.neutral006};
                  }

                  &:active {
                    color: ${theme.colors.neutral007};
                  }

                  &[disabled] {
                    color: ${theme.colors.neutral001};
                  }
                }
              `}
            >
              {userCanEdit && rule.status === "off" ? (
                <EditLinkButton ruleId={rule.id} />
              ) : (
                <ViewLinkButton ruleId={rule.id} />
              )}
              {!userCanEdit ? null : (
                <RuleDeleteButton ruleId={rule.id} ruleName={rule.name} />
              )}
            </Flex>
          );
        },
      },
    ],
    [draggerColumnRender, orgId, t, userCanEdit],
  );
}

const RoutingRules: FC = () => {
  const { t } = useTranslation();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const checkRoutePermission = routerUtils.useCheckRoutePermission();
  const userCanEdit = useMemo(
    () =>
      checkRoutePermission("/settings/assignment/routing-rules/edit/:ruleId"),
    [checkRoutePermission],
  );
  const routingRulesQuery = cantata.autoAssignment.useList(
    {
      params: { orgId },
    },
    {
      suspense: true,
      useErrorBoundary: true,
    },
  );
  const columns = useColumns();
  const { isLoading: isSwitchRulePriorityLoading, switchRulePriority } =
    useSwitchRulePriority();

  const disableAddRuleButton =
    routingRulesQuery.data === undefined ||
    routingRulesQuery.data.autoAssignmentRules.length >
      MAX_AUTO_ASSIGNMENT_RULE_COUNT;

  const onDragEnd = useHandler<DndTable.Props["onDragEnd"]>((event) => {
    if (!event.over) return;
    const over = event.over;
    // destination will be `null` when user drop draggable outside the droppable area
    if (
      event.active.id === event.over.id ||
      routingRulesQuery.data === undefined
    )
      return;

    const fromIndex = routingRulesQuery.data.autoAssignmentRules.findIndex(
      ({ id }) => id === event.active.id,
    );

    if (fromIndex === -1) return;

    const toIndex = routingRulesQuery.data.autoAssignmentRules.findIndex(
      ({ id }) => id === over.id,
    );

    if (toIndex === -1) return;

    if (fromIndex === toIndex) return;

    const rulePriority = arrayMoveImmutable(
      routingRulesQuery.data.autoAssignmentRules,
      fromIndex,
      toIndex,
    ).map(({ id }) => id);

    switchRulePriority({ rulePriority });
  });

  return (
    <MainLayout.Item>
      <Container>
        <Description>{t("assignment.headlineDescription")}</Description>
        <Alert message={t("assignment.routingRules.desc")} />
      </Container>
      <Flex
        css={css`
          justify-content: flex-end;
          margin-bottom: 16px;
        `}
      >
        {!userCanEdit ? null : (
          <Button
            type="primary"
            to={compileToString("/settings/assignment/routing-rules/create")}
            disabled={disableAddRuleButton}
          >
            {t("assignment.add")}
          </Button>
        )}
      </Flex>
      <Form disabled={isSwitchRulePriorityLoading}>
        <DndTable
          data-test="rules-list-table"
          loading={routingRulesQuery.isLoading}
          rowKey="id"
          onDragEnd={onDragEnd}
          columns={columns}
          dataSource={routingRulesQuery.data?.autoAssignmentRules}
          pagination={false}
        />
      </Form>
    </MainLayout.Item>
  );
};

const Wrapped: FC = () => {
  return (
    <ErrorBoundary.Alert>
      <RoutingRules />
    </ErrorBoundary.Alert>
  );
};

export { Wrapped as RoutingRules };
