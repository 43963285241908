import type { ComponentProps } from "@chatbotgang/etude/react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { random } from "@chatbotgang/etude/string/random";
import { css } from "@emotion/react";
import type { Overwrite } from "@mui/types";
import { BASE_URL } from "@zeffiroso/env";
import { theme } from "@zeffiroso/theme";
import type { HtmlDataAttributes } from "@zeffiroso/utils/react/HtmlDataAttributes";
import type { ButtonProps as AntButtonProps } from "antd";
import { Button as AntButton } from "antd";
import { addMilliseconds } from "date-fns";
import { noop } from "lodash-es";
import path from "pathe";
import type { ElementRef } from "react";
import React, { useEffect, useImperativeHandle, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { createWithEqualityFn } from "zustand/traditional";

import { MotifIcon } from "@/components/MotifIcon";
import { withHotKey, type WithHotKeyProps } from "@/hoc/withHotkey";
import type { CompiledToString } from "@/router/utils/compileTo";
import { shouldProcessLinkClick } from "@/shared/event/shouldProcessLinkClick";

namespace Button {
  export type Props = Overwrite<
    ComponentProps<typeof AntButton>,
    WithHotKeyProps &
      HtmlDataAttributes & {
        to?: CompiledToString;
        clickCountDownMs?: number;
        clickCountDownColor?: string;
        external?: boolean;
        /**
         * TODO: Remove this prop after upgrading to antd>=5.17.0.
         */
        iconPosition?: "start" | "end";
        imperativeHandleRef?: React.Ref<{
          abortClickCountDown: () => void;
        }>;
      }
  >;
  export type Ref = ElementRef<typeof AntButton>;
  export interface Type {
    (props: Props, ref: React.ForwardedRef<Ref>): React.ReactNode;
  }
}

const seed = random();

const cssClickCountDownProgressColorVariableName = `--click-count-down-progress-color-${seed}`;
const cssClickCountDownProgressVariableName = `--click-count-down-progress-${seed}`;

const cssButton = css`
  @layer emotion-component {
    &.ant-btn-primary:not(
        [disabled],
        .ant-btn-dangerous,
        .ant-btn-disabled,
        .ant-btn-background-ghost
      ) {
      &.ant-btn-dashed {
        border-color: ${theme.colors.neutral006};
      }
    }

    &.ant-btn-disabled {
      border-color: ${theme.colors.neutral004};
      background: ${theme.colors.neutral002};

      &:hover {
        border-color: ${theme.colors.neutral004};
        background: ${theme.colors.neutral002};
      }
    }

    & > span {
      font-size: inherit;
    }

    & svg {
      font-size: inherit;
    }
  }
`;

const cssClickCountDownProgressing = css`
  @layer emotion-component {
    &.ant-btn-primary:not(
        [disabled],
        .ant-btn-disabled,
        .ant-btn-background-ghost
      ) {
      background-image: linear-gradient(
        to right,
        var(${cssClickCountDownProgressColorVariableName})
          var(${cssClickCountDownProgressVariableName}),
        transparent var(${cssClickCountDownProgressVariableName})
      );
      cursor: progress;
    }
  }
`;

const cssIconEnd = css({
  display: "flex",
  gap: "0.25rem",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "row-reverse",
  ".ant-btn-icon": {
    marginInlineEnd: 0,
  },
});

/**
 * FIXME: Support navigate in ant design message.
 * Try to use navigate from react-router-dom.
 * Ant design message will be thrown because it's not in a react-router-dom context.
 * So we don't support navigate in ant design message for now.
 * Probably won't fix this issue.
 */
function useButtonNavigate() {
  try {
    return useNavigate();
  } catch {
    return undefined;
  }
}

function useCountDownController() {
  const countDownController = useMemo(function setupCountDownController() {
    type CountDown = {
      abort: () => void;
      startDate: Date;
      endDate: Date;
      /**
       * From 100 to 0.
       */
      progress: number;
    };
    const useStore = createWithEqualityFn<{
      countDown: CountDown | null;
    }>(() => ({
      countDown: null,
    }));
    function clear() {
      const countDown = useStore.getState().countDown;
      if (!countDown) return;
      useStore.setState({
        countDown: null,
      });
      countDown.abort();
    }
    function start({ countDownMs }: { countDownMs: number }) {
      clear();
      return new Promise<void>((resolve) => {
        const timeout = setTimeout(function countDown() {
          resolve();
          clear();
        }, countDownMs);
        function abort() {
          clearTimeout(timeout);
          clear();
        }
        const now = new Date();
        useStore.setState({
          countDown: {
            startDate: now,
            endDate: addMilliseconds(now, countDownMs),
            progress: 100,
            abort,
          },
        });
      });
    }
    const cleanUpTasks: Array<() => void> = [];
    let cancelLastUpdateProgress: () => void = noop;
    cleanUpTasks.push(
      useStore.subscribe(function updateProgress(state) {
        if (!state) return;
        cancelLastUpdateProgress();
        let canceled = false;
        cancelLastUpdateProgress = function cancelLastUpdateProgress() {
          canceled = true;
        };
        function updateProgress() {
          if (canceled) return;
          const { countDown } = useStore.getState();
          if (!countDown) return;
          const currentProgress = countDown.progress;
          const nextProgress =
            100 -
            Math.max(
              Math.min(
                100,
                ((Date.now() - countDown.startDate.getTime()) /
                  (countDown.endDate.getTime() -
                    countDown.startDate.getTime())) *
                  100,
              ),
              0,
            );
          if (nextProgress !== currentProgress) {
            useStore.setState((state) => {
              if (!state.countDown) return {};
              return {
                countDown: {
                  ...state.countDown,
                  progress: nextProgress,
                },
              };
            });
          }
          requestAnimationFrame(updateProgress);
        }
        updateProgress();
      }),
    );
    function useCleanup() {
      useEffect(function cleanupOnUnMount() {
        return function cleanup() {
          cancelLastUpdateProgress();
          clear();
          for (const task of cleanUpTasks) {
            task();
          }
        };
      }, []);
    }

    function useIsCounting() {
      const countDown = useStore((state) => state.countDown);
      return Boolean(countDown);
    }

    return {
      useStore,
      start,
      clear,
      useCleanup,
      useIsCounting,
    };
  }, []);
  countDownController.useCleanup();
  return countDownController;
}

const ButtonInternal: Button.Type = forwardRef(function ButtonInternal(
  {
    external,
    style,
    clickCountDownMs = 0,
    iconPosition = "start",
    imperativeHandleRef,
    to,
    ...props
  },
  ref,
) {
  const navigate = useButtonNavigate();
  const isExternal = useMemo<boolean>(
    () => external ?? Boolean(props.href),
    [external, props.href],
  );
  const onClick = useHandler<AntButtonProps["onClick"]>((...args) => {
    props.onClick?.(...args);
    // If no href, do nothing.
    // If preventDefault is set, do nothing.
    if (args[0].isDefaultPrevented()) return;

    if (to) {
      if (shouldProcessLinkClick(args[0], props.target)) {
        args[0].preventDefault();
        navigate?.(to);
      }
    }
  });
  const countDownController = useCountDownController();
  const countDownState = countDownController.useStore(
    (state) => state.countDown,
  );
  const isCountingDown = countDownController.useIsCounting();
  const imperativeHandle = useMemo(
    () => ({
      abortClickCountDown: countDownController.clear,
    }),
    [countDownController.clear],
  );
  useImperativeHandle(imperativeHandleRef, () => imperativeHandle, [
    imperativeHandle,
  ]);
  const onClickWithCountDown = useHandler<AntButtonProps["onClick"]>(
    async function onClickWithCountDown(...args) {
      if (countDownController.useStore.getState().countDown) return;
      await countDownController.start({
        countDownMs: clickCountDownMs,
      });
      onClick(...args);
    },
  );
  const propsWithHref = useMemo(
    () =>
      to
        ? { href: path.join(BASE_URL, to) }
        : /**
           * NOTICE: This is unstable if router is changed to hash or
           * `BASE_URL` is changed other than `''`.
           */
          { href: props.href },
    [props.href, to],
  );
  const propsForExternal = useMemo(
    () =>
      isExternal
        ? {
            target: "target" in props ? props.target : "_blank",
            rel: "rel" in props ? props.rel : "noopener noreferrer",
            icon:
              "icon" in props ? (
                props.icon
              ) : (
                <MotifIcon un-i-motif="new_window" />
              ),
          }
        : {},
    [isExternal, props],
  );

  const resolvedStyle = useMemo<ComponentProps<"button">["style"]>(() => {
    return {
      ...style,
      ...(!clickCountDownMs
        ? null
        : (function getStaticButtonStyle(): ComponentProps<"button">["style"] {
            if (!clickCountDownMs) return {};
            const clickCountDownColor =
              props.clickCountDownColor ??
              (props.danger ? theme.colors.red007 : theme.colors.blue007);
            return {
              [cssClickCountDownProgressColorVariableName]: clickCountDownColor,
              [cssClickCountDownProgressVariableName]: `${countDownState?.progress ?? 0}%`,
            };
          })()),
    };
  }, [countDownState?.progress, clickCountDownMs, props, style]);

  return (
    <AntButton
      style={resolvedStyle}
      css={css([
        cssButton,
        isCountingDown && cssClickCountDownProgressing,
        iconPosition === "end" && cssIconEnd,
      ])}
      ref={ref}
      {...props}
      onClick={clickCountDownMs ? onClickWithCountDown : onClick}
      {...propsWithHref}
      {...propsForExternal}
    />
  );
} satisfies Button.Type);

const Button = withHotKey(ButtonInternal);

export { Button };

/**
 * @deprecated Use `Button` instead.
 */
type ButtonProps = Button.Props;
export type { ButtonProps };
