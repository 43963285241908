import type { CantataTypes } from "@/cantata/types";

/**
 * Slack: [#product-caac](https://chatbotgang.slack.com/archives/C02R6ETJMEY/p1705395186245709?thread_ts=1705376535.554049&cid=C02R6ETJMEY)
 */
const MESSENGER_SCOPE = [
  "pages_show_list",
  "pages_manage_metadata",
  "pages_messaging",
  "business_management",
  "pages_read_engagement",
  "page_events",
].join(",");

/**
 * Notion: [CAAC IG messaging integration note](https://www.notion.so/cresclab/CAAC-IG-messaging-integration-note-75e99e3b6b2a4f77becf9f37adbd47e2?pvs=4#613d771804b74c209f695b2ccb5afc4a)
 */
const IG_SCOPE = [
  "business_management",
  "instagram_basic",
  "instagram_manage_messages",
  "pages_manage_metadata",
  "pages_messaging",
  "pages_read_engagement",
  "pages_show_list",
].join(",");

const channelTypeScopeMap: Record<
  CantataTypes["EditableMetaChannelType"],
  string
> = {
  fb: MESSENGER_SCOPE,
  ig: IG_SCOPE,
};

export { channelTypeScopeMap };
