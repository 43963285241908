import { makeApi } from "@zodios/core";
import { z } from "zod";

import { MemberSchema } from "../models";

const api = makeApi([
  /**
   * Spec: [Notion](https://www.notion.so/cresclab/202502-AI-Assistant-711518fb9fa145ffbf6a9722f5e87be6?pvs=4#ff2eac6f49a94b19a085aa8549e968f2)
   */
  {
    alias: "enabledAiCopilot",
    method: "get",
    path: "api/v1/orgs/:orgId/chat/ai/assistant",
    response: z.object({
      enabled: z.boolean(),
    }),
  },
  {
    /**
     * Spec: [Notion](https://www.notion.so/cresclab/202502-AI-Assistant-711518fb9fa145ffbf6a9722f5e87be6?pvs=4#31883b007d48411abcb1e5343b7a4bde)
     */
    alias: "suggestedReply",
    method: "post",
    path: "api/v1/orgs/:orgId/chat/ai/assistant/suggested-reply",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({
          memberId: MemberSchema.shape.id,
        }),
      },
    ],
    response: z.object({
      content: z.string(),
    }),
  },
  {
    /**
     * Spec: [Notion](https://www.notion.so/cresclab/202409-Workduo-AI-255a0d4262a44423b2e1c3f24f7ccca4?pvs=4#f5d8962f106c4d288bed6473842195a2)
     */
    alias: "rewrite",
    method: "post",
    path: "api/v1/orgs/:orgId/chat/ai/assistant/rewrite",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({
          response: z.string(),
          command: z.string(),
        }),
      },
    ],
    response: z.object({
      content: z.string(),
    }),
  },
]);

export { api };
