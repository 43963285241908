import { makeApi } from "@zodios/core";
import { z } from "zod";

import { WhatsappAttachmentTypeSchema } from "../models";

const api = makeApi([
  /**
   * Directs WhatsApp to prepare the attachment from the provided URL in
   * readiness for sending.
   *
   * Spec:
   * [Notion](https://www.notion.so/cresclab/202501-WhatApp-Integration-4484ac8045d44151b21d1eb06fce18ba?pvs=4#b94eea4fea8748a08453abbf907b754c)
   */
  {
    alias: "create",
    method: "post",
    path: "api/v1/orgs/:orgId/chat/channels/:channelId/whatsapp-attachment",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({
          type: WhatsappAttachmentTypeSchema,
          url: z.string(),
        }),
      },
    ],
    response: z.object({
      attachmentId: z.string(),
    }),
  },
]);

export { api };
