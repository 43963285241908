import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import { breakpointApi, theme } from "@zeffiroso/theme";
import { type ElementRef, useMemo } from "react";

import {
  RouteBreadcrumb,
  type RouteBreadcrumbItem,
} from "@/components/Breadcrumb";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { MotifIcon } from "@/components/MotifIcon";
import { defineStyles } from "@/shared/emotion";
import { emotionMedia } from "@/shared/utils/style/emotionMedia";

type HeaderRef = ElementRef<"div">;
type HeaderProps = ComponentProps<"div"> & {
  breadcrumbItems?: Array<RouteBreadcrumbItem>;
};

const stylesHeaderGteTabletLandscape = defineStyles({
  root: css([
    {
      fontSize: "1.5rem",
      fontWeight: 700,
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      gap: 8,
      paddingTop: 24,
      paddingInline: 16,
    },
    emotionMedia(
      css,
      ">=tabletLandscape",
      (css) => css`
        padding-inline: 40px;
      `,
    ),
  ]),
  title: css({
    fontSize: "1.5rem",
    fontWeight: 700,
    color: theme.colors.neutral010,
    margin: 0,
  }),
});

const HeaderGteTabletLandscape = forwardRef<HeaderRef, HeaderProps>(
  function HeaderGteTabletLandscape(
    { breadcrumbItems, children, ...props },
    ref,
  ) {
    return (
      <div css={stylesHeaderGteTabletLandscape.root} {...props} ref={ref}>
        {!breadcrumbItems ? null : <RouteBreadcrumb items={breadcrumbItems} />}
        {!children ? null : (
          <h1 css={stylesHeaderGteTabletLandscape.title}>{children}</h1>
        )}
      </div>
    );
  },
);

const stylesLteMobile = defineStyles({
  header: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBlock: 8,
    paddingInline: 12,
    gap: 12,
  }),
  closeButton: css({
    padding: 4,
    color: theme.colors.neutral009,
  }),
  title: css({
    flex: 1,
    fontSize: "1.25rem",
    fontWeight: 500,
    color: theme.colors.neutral010,
  }),
});

const HeaderLteMobile = forwardRef<HeaderRef, HeaderProps>(
  function HeaderLteMobile({ breadcrumbItems, children, ...props }, ref) {
    const previousLink = useMemo(() => {
      const previousTo = breadcrumbItems?.[breadcrumbItems.length - 2]?.to;
      if (!previousTo) {
        return "/";
      }
      return RouteBreadcrumb.compileTo(previousTo);
    }, [breadcrumbItems]);
    return (
      <div css={stylesLteMobile.header} {...props} ref={ref}>
        <NarrowIconButton
          css={stylesLteMobile.closeButton}
          // @ts-expect-error -- Ignored.
          to={previousLink}
          icon={<MotifIcon un-i-motif="arrow_left" />}
        />
        <div css={stylesLteMobile.title}>{children}</div>
      </div>
    );
  },
);

const Header = forwardRef<HeaderRef, HeaderProps>(function Header(props, ref) {
  const isGteTabletLandscape = breakpointApi.useGte("tabletLandscape");
  const Component = useMemo(
    () => (isGteTabletLandscape ? HeaderGteTabletLandscape : HeaderLteMobile),
    [isGteTabletLandscape],
  );
  return <Component {...props} ref={ref} />;
});

export { Header };
export type { HeaderProps, HeaderRef };
