import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { checkKey } from "@chatbotgang/etude/event/keycode";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { createContext } from "@chatbotgang/etude/react/createContext";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { random } from "@chatbotgang/etude/string/random";
import { safeString } from "@chatbotgang/etude/string/safeString";
import { delay } from "@chatbotgang/etude/timer/delay";
import { css, keyframes } from "@emotion/react";
import useSwitch from "@react-hook/switch";
import { theme } from "@zeffiroso/theme";
import { injectAbortAndReset } from "@zeffiroso/zodios/injectAbortAndReset";
import { secondsToMilliseconds } from "date-fns";
import {
  type ComponentRef,
  type FC,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { useFeatureFlag } from "@/app/featureFlag";
import { Trans } from "@/app/i18n/Trans";
import { cantata } from "@/cantata";
import { Button } from "@/components/Button";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { useMergeFormDisabled } from "@/components/Form/DisabledContext";
import { InputTextArea } from "@/components/Input";
import { MotifIcon } from "@/components/MotifIcon";
import { Popover } from "@/components/Popover";
import { useLegatoEvent } from "@/legato";
import { ga4Event } from "@/lib/ga4";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { orgQueriesContext } from "@/queriesContext/orgQueriesContext";
import {
  EasyForm,
  useEasyForm,
} from "@/routes/Chat/ui/ChatPanel/Editor/Old/EasyForm";
import { Submit } from "@/routes/Chat/ui/ChatPanel/Editor/Old/Submit";
import { Layout } from "@/routes/Chat/ui/Layout";
import { cssFlexInheritAndFill, defineStyles } from "@/shared/emotion";
import { useInputComposition } from "@/shared/hooks/useInputComposition2";
import { ThreeDot } from "@/shared/icons/common/ThreeDots";

const Context = createContext<ReturnType<typeof useSetupAiCopilot>>({
  name: "AiCopilot",
});

const useSuggestedReply = injectAbortAndReset(
  cantata.aiAssistant.useSuggestedReply,
);
const useRewrite = injectAbortAndReset(cantata.aiAssistant.useRewrite);

function useSetupAiCopilot() {
  const [open, togglePromptPopover] = useSwitch(false);
  const [regenerateEnabled, toggleRegenerateEnabled] = useSwitch(false);
  const orgId = useActiveOrgIdStore((state) => state.value);
  const [easyForm] = useEasyForm();
  const [messageBeforeOpen, setMessageBeforeOpen] = useState("");
  const member = memberQueriesContext.useMember();
  const [prompt, setPrompt] = useState("");
  const orgLevelData = orgQueriesContext.useData();
  const userId = orgLevelData.me.id;

  const suggestMutation = useSuggestedReply(
    {
      params: {
        orgId,
      },
    },
    {
      onSuccess(result) {
        if (!open) return;
        easyForm.controller.setFieldValue("message", result.content);
      },
    },
  );

  const rewriteMutation = useRewrite(
    {
      params: {
        orgId,
      },
    },
    {
      onSuccess(result) {
        const message = result.content;
        setPrompt("");
        easyForm.controller.setFieldValue("message", message);
      },
    },
  );

  const isSuggestedReplyEmpty = useMemo(
    () => suggestMutation.isSuccess && suggestMutation.data.content === "",
    [suggestMutation.data?.content, suggestMutation.isSuccess],
  );

  const isRewriteEmpty = useMemo(
    () => rewriteMutation.isSuccess && rewriteMutation.data.content === "",
    [rewriteMutation.data?.content, rewriteMutation.isSuccess],
  );

  const isEmpty = useMemo(
    () => isSuggestedReplyEmpty || isRewriteEmpty,
    [isRewriteEmpty, isSuggestedReplyEmpty],
  );

  const resetAndAbortAllMutations = useHandler(
    function resetAndAbortAllMutations() {
      suggestMutation.abortAndReset();
      rewriteMutation.abortAndReset();
    },
  );

  const suggest = useHandler<
    (options?: Parameters<typeof suggestMutation.mutate>[1]) => void
  >(async (options) => {
    resetAndAbortAllMutations();
    // Wait for the abort signal to be updated
    await delay(1);
    suggestMutation.mutate(
      {
        memberId: member.id,
      },
      options,
    );
  });

  const rewrite = useHandler<
    (options?: Parameters<typeof rewriteMutation.mutate>[1]) => void
  >(async (options) => {
    resetAndAbortAllMutations();
    // Wait for the abort signal to be updated
    await delay(1);
    const message = easyForm.controller.getFieldValue("message");
    rewriteMutation.mutate(
      {
        response: message,
        command: prompt,
      },
      options,
    );
  });

  const isLoading = useMemo(
    () => suggestMutation.isLoading || rewriteMutation.isLoading,
    [suggestMutation.isLoading, rewriteMutation.isLoading],
  );

  const openPromptPopover = useHandler(function openPromptPopover() {
    if (open) return;
    // store message before AI Copilot open
    setMessageBeforeOpen(easyForm.controller.getFieldValue("message"));
    suggest();
    togglePromptPopover.on();

    ga4Event("aiCopilot", {
      orgId,
      channelId: member.channelId,
      orgUserId: userId,
      memberId: member.id,
      type: "suggestedReply",
    });
  });

  const reset = useHandler(function reset() {
    setPrompt("");
    setMessageBeforeOpen("");
    togglePromptPopover.off();
    toggleRegenerateEnabled.off();

    easyForm.controller.resetFields();
  });

  const close = useHandler(function close() {
    if (isLoading) return;
    const message = messageBeforeOpen;
    reset();
    easyForm.controller.setFieldValue("message", message);
  });

  useLegatoEvent("messages", function chechMemberIncomingMessage(event) {
    if (
      event.content.senderType !== "member" ||
      event.content.channelId !== member.channelId ||
      event.content.memberId !== member.id ||
      event.content.contentType !== "text"
    )
      return;

    toggleRegenerateEnabled.on();
  });

  const aiCopilot = useMemo(
    () => ({
      open,
      openPromptPopover,
      regenerateEnabled,
      loading: suggestMutation.isLoading || rewriteMutation.isLoading,
      suggest,
      rewrite,
      close,
      prompt,
      setPrompt,
      reset,
      isEmpty,
    }),
    [
      open,
      openPromptPopover,
      regenerateEnabled,
      suggestMutation.isLoading,
      rewriteMutation.isLoading,
      suggest,
      rewrite,
      close,
      prompt,
      reset,
      isEmpty,
    ],
  );
  return aiCopilot;
}

const AiCopilotProvider: FC<
  Omit<ComponentProps<typeof Context.Provider>, "value">
> = (props) => {
  const aiCopilot = useSetupAiCopilot();
  return <Context.Provider value={aiCopilot} {...props} />;
};

const cssVarPrefix = `--ai-theme-box-${random()}-` as const;
const cssVarAngle = `${cssVarPrefix}angle` as const;
const cssAngleProperty = `@property ${cssVarAngle}` as const;

const loading = keyframes({
  "0%": {
    [cssVarAngle]: "180deg",
  },
  "50%": {
    [cssVarAngle]: "360deg",
  },
  "100%": {
    [cssVarAngle]: "540deg",
  },
});

const styles = defineStyles({
  root: css({
    display: "flex",
    flexDirection: "column",
    position: "relative",
    backgroundColor: theme.colors.white,
  }),

  inner: css([
    cssFlexInheritAndFill,
    {
      flexDirection: "row",
      minHeight: 104,
      fontSize: "0.875rem",
    },
  ]),

  closeWrapper: css({
    display: "flex",
    padding: "10px 16px 10px 0",
  }),

  content: css({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: theme.colors.neutral004,
    gap: "inherit",
  }),

  loadingIcon: css({
    fontSize: 24,
  }),

  actions: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: "8px 16px",
    gap: 16,
  }),

  button: css({
    backgroundColor: theme.colors.purple006,
    color: theme.colors.white,
  }),

  regenerateButton: css({
    "&:not(:disabled)": {
      color: theme.colors.purple006,
    },
  }),

  outer: css({
    backgroundColor: "inherit",
    margin: 2,
    zIndex: 0,
  }),

  aiThemeBox: css({
    position: "absolute",
    borderRadius: 4,
    inset: 0,
    background: `linear-gradient(var(${cssVarAngle}), #C793FF 11%, #BDE3FF 47.5%, #BBF7FF 52.5%, #C793FF 86.5%, #9149F1 97%, #8538EE 99%)`,
    zIndex: 0,
  }),
  angle: css`
    ${cssAngleProperty} {
      inherits: false;
      initial-value: 180deg;
      syntax: "<angle>";
    }
  `,
  animation: css({
    animation: `${loading} 9s infinite linear`,
  }),
});

const Loading: FC = () => {
  return (
    <div css={styles.content}>
      <span css={styles.loadingIcon}>
        <ThreeDot />
      </span>
      <Trans i18nKey="chat.aiCopilot.editor.loading.desc" />
    </div>
  );
};

const Empty: FC = () => {
  return (
    <div css={styles.content}>
      <Trans i18nKey="chat.aiCopilot.editor.empty.desc" />
    </div>
  );
};

const Close: FC = () => {
  const mergeFormDisabled = useMergeFormDisabled();
  const aiCopilot = useAiCopilot();
  const disabled = mergeFormDisabled(aiCopilot.loading);
  return (
    <NarrowIconButton
      disabled={disabled}
      onClick={aiCopilot.close}
      size={28}
      iconSize={16}
      icon={<MotifIcon un-i-motif="cross" />}
    />
  );
};

const RegenerateButton: FC<ComponentProps<typeof Button>> = (props) => {
  const { t } = useTranslation();
  const aiCopilot = useAiCopilot();
  const orgLevelData = orgQueriesContext.useData();
  const member = memberQueriesContext.useMember();
  const regenerate = useHandler(function regenerate() {
    aiCopilot.suggest();

    ga4Event("aiCopilot", {
      orgId: orgLevelData.org.id,
      channelId: member.channelId,
      orgUserId: orgLevelData.me.id,
      memberId: member.id,
      type: "regenerate",
    });
  });
  return (
    <Button
      {...props}
      icon={<MotifIcon un-i-motif="arrow_refresh" />}
      iconPosition="end"
      onClick={regenerate}
    >
      {t("chat.aiCopilot.editor.regenerate")}
    </Button>
  );
};

const PromptInput: FC = () => {
  const { t } = useTranslation();
  const mergeFormDisabled = useMergeFormDisabled();
  const aiCopilot = useAiCopilot();
  const disabled = mergeFormDisabled(aiCopilot.loading);

  const inputRef = useRef<ComponentRef<"input"> | null>(null);
  useEffect(
    /**
     * Delay is required due to transition effect.
     */
    function delayFocus() {
      const timeoutInstance = setTimeout(() => {
        inputRef.current?.focus();
      }, secondsToMilliseconds(0.3));
      return function cleanup() {
        clearTimeout(timeoutInstance);
      };
    },
    [],
  );
  const lteMobile = Layout.breakpointHooks.useLteSm();
  const handleChange = useHandler<
    ComponentProps<typeof InputTextArea>["onChange"]
  >(function handleChange(e) {
    aiCopilot.setPrompt(e.target.value);
  });
  const orgLevelData = orgQueriesContext.useData();
  const member = memberQueriesContext.useMember();
  const handleKeyDown = useHandler<
    ComponentProps<typeof InputTextArea>["onKeyDown"]
  >(function handleKeyDown(e) {
    if (disabled) {
      e.preventDefault();
      return;
    }
    if (isComposition || !aiCopilot.prompt.trim()) return;

    if (checkKey(e, "Enter") && !e.shiftKey && !lteMobile) {
      e.preventDefault();

      aiCopilot.rewrite();
      ga4Event("aiCopilot", {
        orgId: orgLevelData.org.id,
        channelId: member.channelId,
        orgUserId: orgLevelData.me.id,
        memberId: member.id,
        type: "rewrite",
      });
    }
  });

  const { isComposition, props: textAreaCompositionProps } =
    useInputComposition<
      HTMLTextAreaElement,
      ComponentProps<typeof InputTextArea>
    >({
      props: {
        style: { resize: "none", width: 368 },
        autoSize: { minRows: 2 },
        placeholder: t("chat.aiCopilot.action.input.placeholder"),
        onChange: handleChange,
        value: aiCopilot.prompt,
        disabled,
        onKeyDown: handleKeyDown,
        ref: inputRef,
      },
    });

  return <InputTextArea {...textAreaCompositionProps} />;
};

const useAiCopilot = Context.useContext;

const AiCopilot: FC = () => {
  const aiCopilot = useAiCopilot();
  const enabledRewrite = useFeatureFlag("aiAssistantRewrite");

  return (
    <Popover
      arrow
      placement="topLeft"
      content={<PromptInput />}
      open={aiCopilot.open && enabledRewrite}
      getPopupContainer={(triggerNode) =>
        triggerNode.parentElement || document.body
      }
    >
      <NarrowIconButton
        onClick={aiCopilot.openPromptPopover}
        icon={
          <span css={css({ color: theme.colors.purple006 })}>
            <MotifIcon un-i-motif="ai_copilot" />
          </span>
        }
      />
    </Popover>
  );
};

const useEnabledAiCopilot = () => {
  const orgData = orgQueriesContext.useData();
  return orgData.enableAiCopilot;
};

const WrappedAiCopilot: FC = () => {
  const enabledAiCopilot = useEnabledAiCopilot();
  if (!enabledAiCopilot) return null;
  return <AiCopilot />;
};

assignDisplayName(WrappedAiCopilot, "AiCopilot");

namespace AiCopilotEditor {
  export type Props = ComponentProps<"div"> & {
    textareaProps?: ComponentProps<typeof InputTextArea>;
  };
}

const AiCopilotEditor: FC<AiCopilotEditor.Props> = ({
  textareaProps,
  ...props
}) => {
  const aiCopilot = useAiCopilot();

  const orgLevelData = orgQueriesContext.useData();
  const member = memberQueriesContext.useMember();
  const handleSubmit = useHandler(function handleSubmit() {
    ga4Event("aiCopilot", {
      orgId: orgLevelData.org.id,
      channelId: member.channelId,
      orgUserId: orgLevelData.me.id,
      memberId: member.id,
      type: "send",
    });
  });

  const renderContent = aiCopilot.loading ? (
    <Loading />
  ) : aiCopilot.isEmpty ? (
    <Empty />
  ) : (
    <EasyForm.Item
      name="message"
      noStyle
      rules={[
        {
          validator: async (_, value) => {
            if (safeString(value).trim().length === 0)
              throw new Error("required");
          },
          message: "",
        },
      ]}
    >
      <InputTextArea {...textareaProps} />
    </EasyForm.Item>
  );

  const renderButton = aiCopilot.loading ? (
    <>
      <RegenerateButton disabled type="text" />
      <Submit disabled />
    </>
  ) : aiCopilot.isEmpty ? (
    <RegenerateButton type="primary" css={styles.button} />
  ) : (
    <>
      <RegenerateButton
        type="text"
        disabled={!aiCopilot.regenerateEnabled}
        css={styles.regenerateButton}
      />
      <Submit css={styles.button} onClick={handleSubmit} />
    </>
  );

  return (
    <div css={styles.root} {...props}>
      <div
        css={css([
          styles.aiThemeBox,
          styles.angle,
          !aiCopilot.loading ? null : styles.animation,
        ])}
      />
      <div css={styles.outer}>
        <div css={styles.inner}>
          {renderContent}
          <div css={styles.closeWrapper}>
            <Close />
          </div>
        </div>
        <div css={styles.actions}>{renderButton}</div>
      </div>
    </div>
  );
};

const api = Object.assign(WrappedAiCopilot, {
  Editor: AiCopilotEditor,
  Provider: AiCopilotProvider,
  useAiCopilot,
  useEnabledAiCopilot,
});

export { api as AiCopilot };
