import { flutterWebViewAppSdk } from "@zeffiroso/flutter-webview-app-sdk/flutterWebViewAppSdk";
import { blobToDataUrl } from "@zeffiroso/utils/file/blobToDataUrl";
// eslint-disable-next-line no-restricted-imports -- Wrap the internal function to add the `base64` option for Flutter Webview App.
import { downloadFile as downloadFileInternal } from "@zeffiroso/utils/file/downloadFile";

import { getFeatureFlag } from "@/app/featureFlag";

const downloadFile: typeof downloadFileInternal = async function downloadFile(
  options,
) {
  const flutterWebviewAppDownload = getFeatureFlag("flutterWebviewAppDownload");
  if (
    flutterWebviewAppDownload === "always" ||
    (flutterWebviewAppDownload !== "never" &&
      flutterWebViewAppSdk.isFlutterWebViewApp)
  ) {
    const dataUrl = await blobToDataUrl(options.obj);
    await flutterWebViewAppSdk.downloadFile(dataUrl);
    return;
  }
  return downloadFileInternal(options);
};

export { downloadFile };
