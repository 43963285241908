import { memo } from "@zeffiroso/utils/react/memo";

import { Trans } from "@/app/i18n/Trans";
import type { CantataTypes } from "@/cantata/types";

export const AutoAssignmentRuleConditionCategoryLabel = memo(
  function AutoAssignmentRuleConditionCategoryLabel({
    category,
  }: {
    category: CantataTypes["AutoAssignmentRuleCondition"]["category"];
  }) {
    if (category === "all-messages")
      return <Trans i18nKey="assignment.allTextMessage" />;

    if (category === "keywords") return <Trans i18nKey="assignment.keyword" />;

    if (category === "tags") return <Trans i18nKey="common.tags" />;

    const shouldBeNever: never = category;
    return shouldBeNever;
  },
);
