import { forwardRef } from "@chatbotgang/etude/react/forwardRef";

import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { DeleteOutlined } from "@/shared/icons/common/DeleteOutlined";

export const DeleteMessageButton = forwardRef(function DeleteMessageButton(
  props,
  ref,
) {
  return (
    <NarrowIconButton
      {...props}
      icon={<DeleteOutlined />}
      iconSize={20}
      ref={ref}
    />
  );
} satisfies NarrowIconButton.Type);
