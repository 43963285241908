import { CheckOutlined, CopyOutlined } from "@ant-design/icons";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";
import copy from "copy-to-clipboard";
import { useEffect, useRef, useState } from "react";

import { Trans } from "@/app/i18n/Trans";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { useMessage } from "@/components/message";
import { Tooltip } from "@/components/Tooltip";

const CheckIcon = styled(CheckOutlined)`
  color: ${theme.colors.green007};
`;

const CopyIcon = styled(CopyOutlined)`
  color: ${theme.colors.neutral007};

  &:hover {
    color: ${theme.colors.primaryHover};
  }
`;

namespace CopyButton {
  export type Ref = NarrowIconButton.Ref;
  export type Props = Omit<
    NarrowIconButton.Props,
    "href" | "onClick" | "icon" | "to"
  > & {
    text: string;
  };
  export interface Type {
    (props: Props, ref: React.ForwardedRef<Ref>): React.ReactNode;
  }
}

const CopyButton: CopyButton.Type = forwardRef(function CopyButton(
  { text, ...buttonProps },
  ref,
) {
  const message = useMessage();
  const timer = useRef<number>();
  const [copied, setCopied] = useState(false);

  const doCopy = useHandler<NarrowIconButton.Props["onClick"]>(
    async (event) => {
      event.stopPropagation();
      if (copied) return;

      copy(text);

      setCopied(true);
      message.success(
        <Trans i18nKey="common.copiedToClipboard">Copied to clipboard</Trans>,
      );
      timer.current = window.setTimeout(() => {
        setCopied(false);
      }, 2000);
    },
  );

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  return (
    <Tooltip title={<Trans i18nKey="common.copy" />}>
      <NarrowIconButton
        {...buttonProps}
        icon={copied ? <CheckIcon /> : <CopyIcon />}
        onClick={doCopy}
        ref={ref}
      />
    </Tooltip>
  );
} satisfies CopyButton.Type);

export { CopyButton };

/**
 * @deprecated Use `CopyButton` instead.
 */
type CopyButtonProps = CopyButton.Props;
export type { CopyButtonProps };
