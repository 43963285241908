import filenamify from "filenamify/browser";

namespace downloadFile {
  export type Options = {
    obj: Blob;
    fileName: string;
  };
  export type DownloadFileWithHref = (options: {
    href: string;
    fileName: string;
  }) => void;
}

const downloadFileWithHref: downloadFile.DownloadFileWithHref = (options) => {
  const a = document.createElement("a");
  a.href = options.href;
  a.download = filenamify(options.fileName);
  a.click();
};

function downloadFile(options: downloadFile.Options) {
  const href = URL.createObjectURL(options.obj);
  downloadFileWithHref({
    href,
    fileName: options.fileName,
  });
  URL.revokeObjectURL(href);
}

export { downloadFile };
