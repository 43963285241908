import { makeApi } from "@zodios/core";
import { z } from "zod";

const api = makeApi([
  {
    alias: "bind",
    method: "post",
    path: "api/v1/fcm-token/bind",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({
          fcmToken: z.string(),
          source: z.enum([
            "web",
            "web-view-app-android",
            "native-app-android",
            "web-view-app-ios",
            "native-app-ios",
          ]),
        }),
      },
    ],
    response: z.object({
      fcmToken: z.string(),
    }),
  },
  {
    alias: "revoke",
    method: "post",
    path: "api/v1/fcm-token/revoke",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({
          fcmToken: z.string(),
        }),
      },
    ],
    response: z.void(),
  },
  {
    alias: "test",
    method: "post",
    path: "api/v1/fcm-token/test",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({
          fcmToken: z.string(),
          title: z.string(),
          body: z.string(),
          silent: z.boolean(),
        }),
      },
    ],
    // Don't care
    response: z.unknown(),
  },
]);

export { api };
