import { useCallback } from "react";
import type { ArrayValues } from "type-fest";

import type { CantataTypes } from "@/cantata/types";
import { useLocaleCompare } from "@/shared/hooks/useLocaleCompare";

type Channel = CantataTypes["Channel"];

/**
 * Slack: [#product-caac](https://chatbotgang.slack.com/archives/C02R6ETJMEY/p1738737935463769?thread_ts=1738735225.666269&cid=C02R6ETJMEY)
 */
const typesOrder = [
  "line",
  "whatsapp",
  "wccs",
  "fb",
  "ig",
] as const satisfies Array<CantataTypes["ChannelType"]>;

if (!import.meta.env.PROD) {
  const anyForAssertion: any = undefined;
  // To ensure that the `typesOrder` array contains all the channel types.
  anyForAssertion as Array<CantataTypes["ChannelType"]> satisfies Array<
    ArrayValues<typeof typesOrder>
  >;
}

export const useSortChannelByStatusAndTypeAndName = () => {
  const localeCompare = useLocaleCompare();
  return useCallback(
    function sortChannelByStatusAndPlatformAndName<
      T extends Pick<Channel, "type" | "status" | "name">,
    >(a: T, b: T): number {
      /**
       * Sort by connection status, then by platform, then by name.
       */
      if (a.status === "connected" && b.status !== "connected") return -1;
      if (a.status !== "connected" && b.status === "connected") return 1;

      const platformComparison =
        typesOrder.indexOf(a.type) - typesOrder.indexOf(b.type);
      if (platformComparison !== 0) {
        return platformComparison;
      }

      return localeCompare(a.name, b.name);
    },
    [localeCompare],
  );
};
