import { Iso8601DateSchema } from "@chatbotgang/etude/zod/Iso8601DateSchema";
import type { FlexContainer } from "@line/bot-sdk";
import { makeApi } from "@zodios/core";
import { z } from "zod";

import {
  MessageDetailSchema,
  MessageMetadataSchema,
  MessagePinnedSchema,
  MessageWithStatusSchema,
} from "../models";

const CreateMessageRequestBodySchema = z.union([
  z.object({
    type: z.literal("text"),
    text: z.string(),
    replyTo: z.number().int().optional(),
  }),
  z.object({
    type: z.literal("image"),
    text: z.literal("an image"),
    /**
     * The same as `originUrl`.
     */
    previewUrl: z.string(),
    originUrl: z.string(),
    attachmentId: z.string().optional(),
    replyTo: z.number().int().optional(),
  }),
  z.object({
    type: z.literal("video"),
    text: z.literal("a video"),
    /**
     * videoThumbnail
     */
    previewUrl: z.string(),
    originUrl: z.string(),
    attachmentId: z.string().optional(),
    replyTo: z.number().int().optional(),
  }),
  z.object({
    type: z.literal("file"),
    text: z.string(),
    previewUrl: z.null(),
    originUrl: z.string(),
    metadata: MessageMetadataSchema,
    attachmentId: z.string().optional(),
    replyTo: z.number().int().optional(),
  }),
  // Deprecated
  z.object({
    type: z.literal("line_flex"),
    text: z.string(),
    lineFlexContent: z.any() as z.ZodType<FlexContainer>,
  }),
]);

type CreateMessageRequestBody = z.infer<typeof CreateMessageRequestBodySchema>;

const api = makeApi([
  {
    alias: "list",
    method: "get",
    path: "api/v1/orgs/:orgId/chat/members/:memberId/messages",
    parameters: [
      {
        type: "Query",
        name: "limit",
        schema: z.number().int(),
      },
      {
        type: "Query",
        name: "cursor",
        schema: z.string().optional(),
      },
    ],
    response: z.object({
      messages: z.array(MessageDetailSchema),
      cursor: z.object({
        after: z.string().nullable(),
        before: z.string().nullable(),
      }),
    }),
  },
  {
    alias: "getById",
    method: "get",
    path: "api/v1/orgs/:orgId/chat/members/:memberId/messages/:messageId",
    response: z.object({
      message: MessageDetailSchema,
      cursor: z.object({
        after: z.string().nullable(),
        before: z.string().nullable(),
      }),
    }),
  },
  {
    alias: "searchFromAMember",
    method: "post",
    immutable: true,
    path: "api/v1/orgs/:orgId/chat/members/:memberId/search-messages",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({
          query: z.string(),
        }),
      },
    ],
    response: z.object({
      matchedMessages: z.array(
        z.object({
          cursor: z.object({
            after: z.string().nullable(),
            before: z.string().nullable(),
          }),
          message: MessageDetailSchema,
        }),
      ),
    }),
  },
  {
    /**
     * @see {@link: https://www.notion.so/cresclab/202311-CAAC-FB-integrate-170397cbc5004166b32bc851890d15ca?pvs=4#1ee9de168fa84377a614bed2c877126a | Error code}
     */
    alias: "batchCreate",
    method: "post",
    path: "api/v1/orgs/:orgId/chat/members/:memberId/messages/batch",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({
          messages: z.array(
            CreateMessageRequestBodySchema.and(
              z.object({
                uuid: z.string(),
              }),
            ),
          ),
          senderSession: z.literal("no use"),
        }),
      },
    ],
    response: z.object({
      messages: z.array(MessageWithStatusSchema),
    }),
  },
  {
    alias: "listPinnedMessages",
    method: "get",
    path: "api/v1/orgs/:orgId/chat/members/:memberId/messages/pin",
    response: z.object({
      messages: z.array(MessagePinnedSchema),
    }),
  },
  {
    alias: "pin",
    method: "post",
    path: "api/v1/orgs/:orgId/chat/members/:memberId/messages/:messageId/pin",
    response: z.object({
      userId: z.number().int(),
      memberId: z.number().int(),
      messageId: z.number().int(),
      createdAt: Iso8601DateSchema,
    }),
  },
  {
    alias: "unpin",
    method: "delete",
    path: "api/v1/orgs/:orgId/chat/members/:memberId/messages/:messageId/pin",
    response: z.void(),
  },
  /**
   * Used as Go To Chat in Conversation History.
   * Doc: [Notion](https://www.notion.so/cresclab/202312-CAAC-Conversation-History-5ac758358bb74cfda2b994b7a54e4cf6?pvs=4#c99d07e7286c482281d3f37473abd369)
   */
  {
    alias: "getLastConversationMessage",
    method: "get",
    path: "api/v1/orgs/:orgId/chat/members/:memberId/history/:conversationId",
    response: z.object({
      cursor: z.object({
        after: z.string().nullable(),
        before: z.string().nullable(),
      }),
      message: MessageDetailSchema,
    }),
  },
  /**
   * Used to list messages in a conversation.
   * Doc: [Notion](https://www.notion.so/cresclab/202312-CAAC-Conversation-History-5ac758358bb74cfda2b994b7a54e4cf6?pvs=4#a2a63e4cbce44789a73e037a7a8a2c4a)
   */
  {
    alias: "listConversationMessages",
    method: "get",
    path: "api/v1/orgs/:orgId/chat/members/:memberId/history/:conversationId/messages",
    parameters: [
      /**
       * 0 < limit <= 300
       */
      {
        type: "Query",
        name: "limit",
        schema: z.number().int(),
      },
      /**
       * Specifies the `cursor` to be used after calling both
       * `getLastConversationMessage` and `listConversationMessages`. Messages
       * are retrieved from the oldest to the newest. If not provided, the
       * query starts from the beginning of the conversation.
       */
      {
        type: "Query",
        name: "cursor",
        schema: z.string().optional(),
      },
    ],
    response: z.object({
      cursor: z.object({
        after: z.string().nullable(),
        before: z.string().nullable(),
      }),
      messages: z.array(MessageDetailSchema),
    }),
  },
]);

export { api };
export type { CreateMessageRequestBody };
